jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.touchmove = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.wheel = {
  setup: function( _, ns, handle ){
      this.addEventListener("wheel", handle, { passive: true });
  }
};
jQuery.event.special.mousewheel = {
  setup: function( _, ns, handle ){
      this.addEventListener("mousewheel", handle, { passive: true });
  }
};

// import { $qs, $qsa } from '/utils/QuerySelector';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import Player from '@vimeo/player';


// import Map from 'map';


gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

jQuery(document).ready(function ($) {
  consoleMessage: {
    console.log(
      "%cCreated by %cTWK%cwww.thewebkitchen.co.uk",
      "background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;",
      "background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;",
      "background: #FFFFFF; padding: 5px 10px;"
    );
  }

  searchAndFilter:{
    $(document).on("sf:ajaxfinish", ".searchandfilter", function(){
ScrollTrigger.refresh();
    })
  }

  smoothScroll: {

    function getCookie(user) {
      var cookieArr = document.cookie.split(";");
      for(var i = 0; i < cookieArr.length; i++) {
          var cookiePair = cookieArr[i].split("=");
          if(user == cookiePair[0].trim()) {
              return decodeURIComponent(cookiePair[1]);
          }
      }
      return null;
  }
  


    if ( $(window).width() > 768 && getCookie("OptanonAlertBoxClosed") && !document.querySelectorAll(".post-type-archive-tribe_events").length && !document.querySelectorAll(".no-smooth-scroll").length) {

    ScrollSmoother.create({
      smooth: 1.5, // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: true, // looks for data-speed and data-lag attributes on elements
      smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });
  }
  }
  modernizer: {
    if (!Modernizr.objectfit) {
      $(".object-fit").each(function () {
        var $container = $(this),
          imgUrl = $container.find("img").prop("src");
        if (imgUrl) {
          $container
            .css("backgroundImage", "url(" + imgUrl + ")")
            .addClass("not-compatible");
        }
      });
    }
  }


  search: {
    $(".search-icon").on("click", function (e) {
      $(".searchform").toggleClass("search-open");
      $(this).toggleClass("submit-zindex");
      $(".select-lang").removeClass("transform-height");
      $("#menu-main-menu-top, .lang_dropdown").fadeOut();
      $("#s").focus();
      setTimeout(function () {
        $(".close-search").fadeIn();
      }, 300);
    });
    $(".close-search").on("click", function (e) {
      $(this).fadeOut();
      $(".searchform").removeClass("search-open");
      $(".search-icon").removeClass("submit-zindex");
      $("#menu-main-menu-top, .lang_dropdown").fadeIn();
    });
  }

  oldImages: {
    if (document.querySelectorAll(".fsImage").length) {
      document.querySelectorAll(".fsImage").forEach((image) => {
        console.info(image);
      });
    }
  }
  menu: {
    console.info(" mneu");
    gsap.set($(".sub-menu"), { autoAlpha: 0 });

    // $('[data-js="full-menu-toggle"]').on('click', function (e) {
    // 	console.info('open mneu');
    // 	gsap.to($('[data-js="main-nav"]'), { autoAlpha: 1 });
    // });
    // $('[data-js="full-menu-toggle"]').on('click', function (e) {
    // 	console.info('open mneu');
    // 	gsap.to($('[data-js="main-nav"]'), { autoAlpha: 1 });
    // });

    // Add needed classes to the menu - ex. current-page-ancestor
    let activeMenuItem = document.querySelector(".current-page-item");

    if (activeMenuItem) {
      activeMenuItem.parentNode?.parentNode?.classList.add(
        "current-page-ancestor"
      );
    }

    const hamburger = document.querySelector('[data-js="full-menu-toggle"]');
    const bgO = document.querySelector('[data-js="bg-o"]');
    const bgOwhite = document.querySelector('[data-js="bg-white-o"]');
    const menus = document.querySelector('[data-js="main-nav"]');
    const backLink           = document.querySelectorAll( '.sub-menu .menu-back')
    let isMobile = false
		if ( $(window).width() < 768 ) {
			isMobile = true
		}

    let pinNavigation = ScrollTrigger.create({
      trigger: "body",
      pin: '[data-pin="navigation"]',
      pinSpacing: false,
      start: "top top",
      end: "bottom bottom",
      markers: false,
     
    });

    const pinElements = document.querySelectorAll('[data-fixed="true"]');
    pinElements.forEach(element => {
      let pinNavigation = ScrollTrigger.create({
        trigger: "body",
        pin: element,
        pinSpacing: false,
        start: "top top",
        end: "bottom bottom",
        markers: false,
       
      });
      
    });
    ScrollTrigger.create({
      trigger: "body",
      pin: '[data-pin="search-popup"]',
      pinSpacing: false,
      start: "top top",
      end: "bottom bottom",
      markers: false,
     
    });
    
    ScrollTrigger.create({
      trigger: "body",
      pin: '[data-js="main-nav"]',
      pinSpacing: false,
      start: "top top",
      end: "bottom bottom",
      markers: false,
     
    });

    let pinNavigationAnim = gsap.timeline();

    pinNavigationAnim
    .fromTo('[data-js="no-show-scroll"]', {autoAlpha: 1},{autoAlpha: 0, duration:0.3})
    .fromTo('[data-js="show-scroll"]', {autoAlpha: 0, scale: 0.8},{autoAlpha: 1, duration:0.3, scale:1});

    let pinNavigationAnimTrigger = ScrollTrigger.create({
      trigger: "body",
      toggleActions: "play none reverse none",
      start: "200 top",
      end: "200 top",
      markers: false,
      scrub: false,
      animation: pinNavigationAnim
     
    });

    const subMenus = document.querySelectorAll(
      '[data-js="main-nav"] .sub-menu'
    );
    const mainMenuItems = document.querySelectorAll(
      '[data-js="main-nav"] #main-menu > li.menu-item > .link-container'
    );
    const secondaryMenuItems = document.querySelectorAll(
      '[data-js="main-nav"] #secondary-menu > li.menu-item > .link-container'
    );
    const hasChildren = document.querySelectorAll(
      '[data-js="main-nav"] .menu-item-has-children > .link-container > a'
    );
    const hasNoChildren = document.querySelectorAll(
      '[data-js="main-nav"] .menu-item:not(.menu-item-has-children) > .link-container > a'
    );
    // const menuContainer      = document.querySelector( '.top-nav-menu-container')
    const socialMediaIcons = document.querySelector(
      ".top-nav-menu-container .social-media"
    );
    const hamlines = hamburger.querySelectorAll(".block span");

    let hamtl = gsap.timeline({ paused: true });
    hamtl
      .to(hamlines[1], { opacity: 0, duration: 0.3 }, "start")
      .to(
        hamlines[0],
        {
          rotateZ: 45,
          y: "-0px",
          transformOrigin: "center center",
          duration: 0.3,
        },
        "start"
      )
      .to(
        hamlines[2],
        {
          rotateZ: -45,
          y: "-5px",
          transformOrigin: "center center",
          duration: 0.3,
        },
        "start"
      );

    // Open and close menu - Hamburger clicks.
    hamburger.addEventListener(
      "click",
      function () {
        hamburger.classList.toggle("open");

        if (hamburger.classList.contains("open")) {
          // Opening the menu
          menus.classList.add("open");
          gsap.to(menus, { autoAlpha: 1 });

          hamtl.play();
          $("body").addClass("h-full overflow-hidden");

          let innerPage = false;
          mainMenuItems.forEach((menuItem) => {
            if (
              menuItem.parentNode.classList.contains("current-page-ancestor")
            ) {
              innerPage = true;
              // menuItem.querySelector("a").click();
            }
          });

          if (!innerPage) {
            let tl = gsap.timeline();
            tl.fromTo(
              mainMenuItems,
              { alpha: 0, y: 50 },
              { alpha: 1, y: 0, stagger: 0.1 }
            )
              .fromTo(
                secondaryMenuItems,
                { alpha: 0, y: 50 },
                { alpha: 1, y: 0, stagger: 0.1 },
                "<+0.4"
              )
              .fromTo(socialMediaIcons, { alpha: 0 }, { alpha: 1 })
              .fromTo(bgO, { opacity: 0 }, { opacity: 0.05 }, "-=1");
          } else {
            let tl = gsap.timeline();
            tl.set(mainMenuItems, { alpha: 1, y: 0 })
              .fromTo(
                secondaryMenuItems,
                { alpha: 0, y: 50 },
                { alpha: 1, y: 0, stagger: 0.1 },
                "<+0.4"
              )
              .fromTo(socialMediaIcons, { alpha: 0 }, { alpha: 1 })
              .fromTo(bgO, { opacity: 0 }, { opacity: 0.05 });
          }
          // Open submenu if in current subpage.
        } else {
          // Closing the menu
          menus.classList.remove("open");
          $("body").removeClass("h-full overflow-hidden");

          hamtl.reverse();

          let tl = gsap.timeline();
          tl.to(menus, { autoAlpha: 0 });
        }
      },
      false
    );

    // Open and close sub-menu - Menu item with children clicks.
    hasChildren.forEach((menuItem) => {
      menuItem.addEventListener(
        "click",
        function (e) {
          e.preventDefault();
          // gsap.to(mainMenuItems, {opacity: 0.5, duration:0.3, overwrite: true})

          gsap.to(bgO, { opacity: 0, duration: 0.3 });
          gsap.to(bgOwhite, { opacity: 0.05, duration: 0.3 });
          gsap.to(menus, { backgroundColor: "#a00e22", duration: 0.3 });

          let mainMenuItemsFiltered = [...mainMenuItems].filter(
            (mainMenuItem) => {
              return (
                mainMenuItem.closest("li").classList !=
                menuItem.closest("li").classList
              );
            }
          );

          gsap.to(mainMenuItemsFiltered, {
            opacity: 0.5,
            duration: 0.3,
            overwrite: true,
          });
          gsap.to(menuItem.closest(".link-container"), {
            opacity: 1,
            duration: 0.3,
            overwrite: true,
          });

          // gsap.set(menuItem.closest('.link-container'), {opacity: 1});
          if (
            !menuItem
              .closest(".link-container")
              .classList.contains("sub-menu-open")
          ) {
            const subMenu = menuItem
              .closest(".menu-item")
              .querySelector(".sub-menu");
            const subMenuItems = subMenu.querySelectorAll("li.menu-item");

            // Opening the sub-menu
            let tl = gsap.timeline();
            if ( isMobile ) {
              tl
                .to( subMenus, { x: '100vw', autoAlpha: 0 }, '<' )
                .to( subMenu, { x: '0vw', autoAlpha: 1 }, '<' )
                .fromTo( subMenuItems, { alpha: 0, y: 50 }, { alpha:1, y: 0, stagger: 0.1 }, '<+0.4' )
            } else {
            tl.to(subMenus, { autoAlpha: 0 }, "<")
              .to(subMenu, { autoAlpha: 1 }, "<")
              .fromTo(
                subMenuItems,
                { alpha: 0, y: 50 },
                { alpha: 1, y: 0, stagger: 0.1 },
                "<+0.4"
              );
            }

            const linkContainer = document.querySelectorAll(".link-container");
            for (var i = 0; i < linkContainer.length; ++i) {
              linkContainer[i].classList.remove("sub-menu-open");
            }
            menuItem.closest(".link-container").classList.add("sub-menu-open");
          }
        },
        false
      );
    });

    // Close menu before loading a new page - Menu item clicks.
    hasNoChildren.forEach((menuItem) => {
      menuItem.addEventListener(
        "click",
        function (e) {
          // Closing the menu before load (if open in same page)
          if (!e.ctrlKey) {
            gsap.to("body", { alpha: 0, duration: 0.4 });
          }
        },
        false
      );
    });

    backLink.forEach(back => {
			back.addEventListener('click', function () {
        const linkContainer = document.querySelectorAll(".link-container");
        for (var i = 0; i < linkContainer.length; ++i) {
          linkContainer[i].classList.remove("sub-menu-open");
        }
        gsap.to(linkContainer, {
          opacity: 1,
          duration: 0.3,
          overwrite: true,
        });
				gsap.to( subMenus, { x: '100vw', ease: 'power2' } )
			})
		});
  }

  homePageIntro: {
    if ($('[data-js="intro-clip"]').length) {
      const clipElement = document.querySelector('[data-js="intro-clip"]');
      const oElement = document.querySelector('[data-js="intro-clip-svg"] svg');
      const oGreyElement = document.querySelector('[data-js="intro-clip-grey-svg"] svg');
      const oGreyElementContain = document.querySelector('[data-js="intro-clip-grey-svg"]');
      const oElementContain = document.querySelector(
        '[data-js="intro-clip-svg"]'
      );
      const title = document.querySelector('[data-js="intro-clip-title"]');
      const intro = document.querySelector('[data-js="intro-clip-intro"]');
      const scroll = document.querySelector('[data-js="scroll-to"]');
      const overlay = document.querySelector('[data-js="intro-clip-overlay"]');
      const searchPopup = document.querySelector('[data-js="search-popup"]');
      const videoBlocks = document.querySelectorAll(
        '[data-js="intro-video-block"]'
      );

      videoBlocks.forEach(block => {
        block.addEventListener("mouseenter", () => {
          gsap.to(block.querySelector('img'), {scale: 1.1});
        })
        block.addEventListener("mouseleave", () => {
          gsap.to(block.querySelector('img'), {scale: 1});
        })

      })

      
      const navigation = document.querySelectorAll('[data-js="navigation"]');
      const bannerHome = document.querySelector(
        '[data-js-trigger="banner-home"]'
      );
      // const customCursor = document.querySelector(
      //   '[data-js="custom-cursor-home"]'
      // );
      const durationLength = 10;

      let bannerLoaded = false;
      gsap.set(navigation, { opacity: 0 });
      gsap.set(title, { opacity: 0 });
      gsap.set(intro, { opacity: 0 });
      gsap.set(scroll, { opacity: 0 });
      gsap.set(searchPopup, { opacity: 0 });
      gsap.set(clipElement, { clipPath: "circle(170px at center)" });
      gsap.set(oElement, { width: "370px" });
      gsap.set(oElement, { clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)" });
      gsap.set(oGreyElement, { width: "370px" });
      // customCursor.classList.add("force-hide");
      // customCursor.classList.add("opacity-0");

      let circleClipSize = '70vw';
      let redCircle = '160vw';
      // circle is 70% of height or width which ever is more
      if($(window).width() < $(window).height()){
        circleClipSize = '70vh';
        redCircle = '160vh';
      }

      const introClip = gsap.timeline({ delay: 0, paused: false });
      if(sessionStorage['oak_hp_anim'] == 'true'){
        introClip
        .set(clipElement,{clipPath: "circle(2000vh at center)"})
        .set(oElementContain, { autoAlpha: 0 })
        .set(oGreyElementContain,{autoAlpha: 0})
        .set(overlay, { opacity: 0.3, duration: durationLength / 10 }, "afterLoad")
        .to(title, { opacity: 1, duration: durationLength / 12 }, ">")
        .to(intro, { opacity: 1, duration: durationLength / 15 }, ">")
        .to(scroll, { opacity: 1, duration: durationLength / 15 }, ">")
        .to(navigation, { opacity: 1, duration: durationLength / 15 }, ">")
        .to(searchPopup, { opacity: 1, duration: durationLength / 15 }, ">")
      }else{
        sessionStorage['oak_hp_anim'] = 'true';
      introClip
      .to(oElement, { clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: 2 })
      .set(oGreyElementContain,{autoAlpha: 0})
    .addLabel('afterLoad')
        .to(clipElement,{
            clipPath: "circle(" + circleClipSize + " at center)",
            duration: durationLength / 3,
            ease: "power1.in"
          },
          "afterLoad"
        )
        .to(
          oElement,
          { width: redCircle, duration: durationLength / 3, ease: "power1.in" },
          "afterLoad"
        )
        .to(overlay, { opacity: 0.3, duration: durationLength / 10 }, "afterLoad")
        .to(title, { opacity: 1, duration: durationLength / 12 }, ">")
        .to(intro, { opacity: 1, duration: durationLength / 15 }, ">")
        .call(function () {
          console.info('call after intro');
          // customCursor.classList.remove("force-hide");
          bannerLoaded = true;
          ScrollTrigger.refresh();
        },">")
        // .to(customCursor, { autoAlpha: 1, duration: durationLength / 15 })
        .to(scroll, { opacity: 1, duration: durationLength / 15 }, ">")
        .to(navigation, { opacity: 1, duration: durationLength / 15 }, ">")
        .to(searchPopup, { opacity: 1, duration: durationLength / 15 }, ">")

        .set(oElementContain, { autoAlpha: 0 });
      // .set(clipElement, {autoAlpha: 0 })
      }

      const showVideos = gsap.timeline({ paused: true });
      showVideos
      .to([title,intro,scroll],{opacity:0, duration: 0.3},'start')
        .to(clipElement, { y: "-100%", duration: 1 },'start')
        .to(videoBlocks, { y: "-100%", stagger: "0.2", duration: 1 }, ">-75%");
    }
  }

  anchorlinks: {
    $(document).on(
      "click",
      '[data-js="scroll-to"], .smooth-scroll',
      function (event) {
        event.preventDefault();

        $("html, body").animate(
          {
            scrollTop: $($.attr(this, "href")).offset().top,
          },
          500
        );
      }
    );
  }

  externallinks: {
    $('a[href^="mailto:"]').each(function () {
      $(this).addClass("email-link");
    });

    // $('a:not(.email-link):not([class^="magnific-"]):not([data-js="video-popup"])').each(function () {
    //   var a = new RegExp("/" + window.location.host + "/");

    //   if (!a.test(this.href)) {
    //     $(this).click(function (event) {
    //       event.preventDefault();
    //       window.open(this.href, "_blank");
    //     });
    //   }
    // });
    pdfs: {
      // Open PDFs in new window
      $(function () {
        $('a[href$=".pdf"]').prop("target", "_blank");
      });
    }
  }

  hoverSlide: {
    if ($('[data-js="hover-scroll"]').length) {

      let isHover = true
      if ( $(window).width() < 1280 ) {
        isHover = false
      }

      console.info("hoverscroll: " + $(window).width());

      if(isHover){

      // const hoverScroll = $('[data-js="hover-scroll"]');
      // let mouseentered = false;
      // const hoverScrollTL = gsap
      //   .timeline({ paused: true, repeat: -1 })
      //   .fromTo(
      //     hoverScroll,
      //     { x: "0" },
      //     { x: "-30vw", duration: 5, ease: "none" }
      //   );
      // // .to(hoverScroll, { x: "-15%", duration: 5 })
      // hoverScrollTL.progress(0.5);
      // const halfVW = innerWidth / 2;
      // const maxDistance = Math.sqrt(Math.pow(halfVW, 2));

      // hoverScroll
      //   .on("mousemove", (e) => {
      //     // console.info('mousemove');
      //     const x = halfVW - e.pageX;
      //     const distance = Math.sqrt(Math.pow(x, 2));

      //     // console.info(e.pageX / innerWidth);
      //     // console.info(1 - distance / maxDistance);
      //     if (mouseentered) {
      //       hoverScrollTL.progress(e.pageX / innerWidth);
      //       // gsap.to( hoverScrollTL, 0.3, {progress: e.pageX / innerWidth, ease: "power2.inOut" })
      //     }
      //   })
      //   .on("mouseenter", (e) => {
      //     // hoverScrollTL.progress(0.5);
      //     console.info("enter" + e.pageX);
      //     gsap.to(hoverScrollTL, 0.3, {
      //       progress: e.pageX / innerWidth,
      //       ease: "power2.inOut",
      //       onComplete: function () {
      //         mouseentered = true;
      //       },
      //     });
      //   });


      // .on("mouseleave", e => {
      // 	// hoverScrollTL.progress(0.5);
      // 	gsap.to(hoverScrollTL, 0.3, { progress: 0.5, ease: "power2.inOut" })
      // 	mouseentered = false;
      // })
      }else{
        $('[data-js="hover-scroll"]');

        $('[data-js="hover-scroll"]').slick({
          dots: false,
          arrows: false,
          centerMode: true,
          centerPadding: '30px',
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: 'unslick'
            }
          ]
  
        });
      }
    }
  }

  stickyScroll: {
    if (document.querySelectorAll('[dats-js="sticky-scroll"]').length) {
      console.info("piun");
      let st = ScrollTrigger.create({
        trigger: "[dats-js='sticky-scroll-contain']",
        pin: "[dats-js='sticky-scroll']",
        pinSpacing: false,
        start: "80px 80px",
        end: "bottom bottom",
        markers: false,
      });
    }
  }
  scrollAnimations: {
    if (document.querySelectorAll(".banner--half").length) {
      const blocks = document.querySelectorAll(".banner--half");

      [...blocks].forEach((block) => {
        const tl = gsap.timeline({
          defaults: {
            duration: 0.6,
            //ease: "power1.out"
            // ease: CustomEase.create("customEase", "0.215, 0.61, 0.355, 1")
          },
          scrollTrigger: {
            trigger: block,
            fastScrollEnd: true,
            once: true,
            start: "top bottom-=150px",
            end: "bottom 80%", // fastScrollEnd triggers as we leave the section so make sure you have an end position set low down enough to see the impact.
            markers: false,
            toggleClass: "twk-aos-animating",
            onEnter: () => block.classList.add("twk-aos-animate"),
            onLeave: () => block.classList.add("twk-aos-animated"),
          },
        });

        const title = block.querySelector("h1");
        const subTitle = title.previousElementSibling;
        const scrollLink = block.querySelector("a");
        const overlay = block.querySelector('[data-js-anim="overlay"]');

        console.info(title);
        console.info(title.previousSibling);
        tl.fromTo(overlay, { x: "80px" }, { duration: 1, x: 0 }, "start")
          .fromTo(subTitle, { opacity: 0 }, { opacity: 1 }, "start")
          .fromTo(title, { opacity: 0 }, { opacity: 1 }, ">-0.3")
          .fromTo(
            title.nextElementSibling,
            { opacity: 0 },
            { opacity: 1 },
            ">-0.3"
          )
          .fromTo(scrollLink, { opacity: 0 }, { opacity: 1 }, "<");
      });
    }
    if (document.querySelectorAll(".block--sibling-pages").length) {
      console.info("sib");

      const blocks = document.querySelectorAll(".block--sibling-pages");

      [...blocks].forEach((block) => {
        const tl = gsap.timeline({
          defaults: {
            duration: 0.6,
            //ease: "power1.out"
            // ease: CustomEase.create("customEase", "0.215, 0.61, 0.355, 1")
          },
          scrollTrigger: {
            trigger: block,
            fastScrollEnd: true,
            once: true,
            start: "top bottom-=150px",
            end: "bottom 80%", // fastScrollEnd triggers as we leave the section so make sure you have an end position set low down enough to see the impact.
            markers: false,
            toggleClass: "twk-aos-animating",
            onEnter: () => block.classList.add("twk-aos-animate"),
            onLeave: () => block.classList.add("twk-aos-animated"),
          },
        });

        const title = block.querySelector("h2");
        const listItems = block.querySelectorAll("li");
        console.info(title);
        console.info("test");

        tl.fromTo(title, { opacity: 0 }, { opacity: 1 })
          .fromTo(title.nextSibling, { opacity: 0 }, { opacity: 1 }, "-=0.3")
          .fromTo(
            listItems,
            { opacity: 0 },
            { opacity: 1, stagger: 0.08 },
            "-=0.5"
          );
      });
    }
    if (document.querySelectorAll(".block--academic-explainer").length) {


      const blocks = document.querySelectorAll(".block--academic-explainer");

      [...blocks].forEach((block) => {
        const tl = gsap.timeline({
          defaults: {
            duration: 0.6,
            //ease: "power1.out"
            // ease: CustomEase.create("customEase", "0.215, 0.61, 0.355, 1")
          },
          scrollTrigger: {
            trigger: block,
            fastScrollEnd: true,
            once: true,
            start: "top bottom-=150px",
            end: "bottom 80%", // fastScrollEnd triggers as we leave the section so make sure you have an end position set low down enough to see the impact.
            markers: false,
            toggleClass: "twk-aos-animating",
            onEnter: () => block.classList.add("twk-aos-animate"),
            onLeave: () => block.classList.add("twk-aos-animated"),
          },
        });

        const columns = block.querySelectorAll('[data-js-anim="columns"] > div');

        tl
          .fromTo(
            columns,
            { opacity: 0 },
            { opacity: 1, stagger: 0.4, duration: 0.4 },
            "-=0.5"
          );
      });
    }
    if (document.querySelectorAll(".block--stats").length) {
      console.info("sib");

      const blocks = document.querySelectorAll(".block--stats");

      [...blocks].forEach((block) => {
        const tl = gsap.timeline({
          defaults: {
            duration: 0.6,
          },
          scrollTrigger: {
            trigger: block,
            fastScrollEnd: true,
            once: true,
            start: "top bottom-=150px",
            end: "bottom 80%", // fastScrollEnd triggers as we leave the section so make sure you have an end position set low down enough to see the impact.
            markers: false,
            toggleClass: "twk-aos-animating",
            onEnter: () => block.classList.add("twk-aos-animate"),
            onLeave: () => block.classList.add("twk-aos-animated"),
          },
        });

        const listItems = block.querySelectorAll('[data-js-anim="stats"]');

        tl.fromTo(listItems, { opacity: 0 }, { opacity: 1, stagger: 0.15 });
      });
    }
    if (document.querySelectorAll(".block--team").length) {
      const blocks = document.querySelectorAll(".block--team");

      [...blocks].forEach((block) => {
        const tl = gsap.timeline({
          defaults: {
            duration: 0.6,
          },
          scrollTrigger: {
            trigger: block,
            fastScrollEnd: true,
            once: true,
            start: "top bottom-=150px",
            end: "bottom 80%", // fastScrollEnd triggers as we leave the section so make sure you have an end position set low down enough to see the impact.
            markers: false,
            toggleClass: "twk-aos-animating",
            onEnter: () => block.classList.add("twk-aos-animate"),
            onLeave: () => block.classList.add("twk-aos-animated"),
          },
        });

        const title = block.querySelector("h2");
        const listItems = block.querySelectorAll('[data-js-anim="members"]');
        const button = block.querySelectorAll(".button");

        tl.fromTo(title, { opacity: 0 }, { opacity: 1 })
          .fromTo(listItems, { opacity: 0 }, { opacity: 1, stagger: 0.15 })
          .fromTo(button, { opacity: 0 }, { opacity: 1 });
      });
    }
  }

  dragSlider: {
    if ($('[data-js="drag-slider"]').length) {
      $('[data-js="drag-slider"]').slick({
        dots: false,
        arrows: false,
        fade: false,
        autoplay: false,
        infinite: false,
        variableWidth: true,
        swipeToSlide: true,
        draggable: true,
        outerEdgeLimit: true,

        touchThreshold: 10,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              variableWidth: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '30px',

            },
            
          },
        ],

        // autoplaySpeed: 3500,
        // speed: 1200,
      });
    }
  }

  tableResponsiveness:{
    if (document.querySelectorAll('.tablepress').length) {
const tables = document.querySelectorAll('.tablepress');

    var wrap = function (toWrap, wrapper) {
      wrapper = wrapper || document.createElement('div');
      wrapper.classList.add('responsive-table');
      toWrap.parentNode.appendChild(wrapper);
      return wrapper.appendChild(toWrap);
  };
  [...tables].forEach((table) => {
  wrap(table);

  });
}
  }
  newsSlider: {
    if (document.querySelectorAll('[data-js="news-slider"]').length) {
      $('[data-js="news-slider"]').slick({
        dots: false,
        arrows: false,
        // fade: false,
        // autoplay: false,
        infinite: false,
        // swipeToSlide: true,
        // draggable: true,
        centerMode: false,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              variableWidth: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '15px',

            },
            
          },
        ],
        // centerPadding: '30px',

        // touchThreshold: 10,
      });
    }
    if (document.querySelectorAll('[data-js="news-slider-variable-width"]').length) {
      $('[data-js="news-slider-variable-width"]').slick({
        dots: false,
        arrows: false,
        // fade: false,
        // autoplay: false,
        infinite: false,
        // swipeToSlide: true,
        // draggable: true,
        centerMode: false,
  variableWidth: true,
        centerPadding: '15px',

        // touchThreshold: 10,

        responsive: [
          {
            breakpoint: 768,
            settings: {
              variableWidth: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '30px',

            },
            
          },
        ],
      });
    }
  }
  centeredSlider: {
    if (document.querySelectorAll('[data-js="centered-slider"]').length) {
      $('[data-js="centered-slider"]').slick({
        dots: false,
        arrows: false,
        // fade: false,
        // autoplay: false,
        infinite: false,
        // swipeToSlide: true,
        // draggable: true,
        centerMode: true,
        centerPadding: '30px',

        // touchThreshold: 10,
      });
    }
  }

  forms: {
    if (document.querySelectorAll('.gform_wrapper').length) {
      let forms = document.querySelectorAll('.gform_wrapper');
      forms.forEach(form => {
        form.classList.add('not-wysiwyg');
      })
    }
  }
  curriculumSlider: {
    if (document.querySelectorAll('[data-js="curriculum-slider"]').length) {
      let initialSlide = 0;
      if($(window).width() < 768) initialSlide = 1;
      const slider = $('[data-js="curriculum-slider"]').slick({
        dots: false,
        arrows: false,
        fade: true,
        // autoplay: true,
        // infinite: false,
        swipe: false,
        swipeToSlide: false,
        draggable: false,
        touchMove: false,
        initialSlide
        // touchThreshold: 10,
      });

      const title = document.querySelector('[data-js="curriculum-title"]');
      const controls = document.querySelectorAll(
        '[data-js="change-curriculum-slider"]'
      );
      const activeStates = document.querySelectorAll(
        '[data-js="active-curriculum-slider"]'
      );
      const controlAnimations = document.querySelectorAll(
        '[data-js="change-curriculum-slider-animation"]'
      );

      gsap.to(controlAnimations, {
        repeat: -1,
        duration: 1.5,
        opacity: 0,
        scale: 2,
        transformOrigin: "center",
      });

      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: title,
          scrub: true,
          // start: '-=50%',
          end: "+=130%",
          markers: false,
        },
      });

      timeline.fromTo(title, { x: "0%" }, { x: "-100%", ease: "none" });

      controls.forEach((item) => {
        item.addEventListener("click", function (e) {
          console.info("changeslider");
          slider.slick("slickGoTo", item.dataset.slide, false);

          let activeStatesFiltered = [...activeStates].filter((activeState) => {
            return activeState.dataset.slide != item.dataset.slide;
          });

          gsap.to(activeStatesFiltered, {
            opacity: 0,
            scale: 0,
            transformOrigin: "center",
            duration: 0.3,
            overwrite: true,
          });
          gsap.to(
            document.querySelectorAll(
              '[data-js="active-curriculum-slider"][data-slide="' +
                item.dataset.slide +
                '"]'
            ),
            {
              opacity: 1,
              scale: 1,
              transformOrigin: "center",
              duration: 0.3,
              overwrite: true,
            }
          );

          let allAnimationParts = document.querySelectorAll(
            '[data-js="change-curriculum-slider-animation"]'
          );
          let animationParts = document.querySelectorAll(
            '[data-js="change-curriculum-slider-animation"][data-slide="' +
              item.dataset.slide +
              '"]'
          );

          allAnimationParts.forEach((animationPart) => {

            gsap.to(
              animationPart.parentElement,
              { opacity: 1 }
            );
            });
          animationParts.forEach((animationPart) => {
          gsap.to(
            animationPart.parentElement,
            { opacity: 0 }
          );
          });
        });
      });
    }
  }

  slider: {
    $('[data-js="fade-slider"]').slick({
      dots: true,
      arrows: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3500,
      speed: 1200,
    });
    $('[data-js="insta-fade-slider"]').slick({
      dots: false,
      arrows: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3500,
      speed: 1200,
    });

    /**
     * Gallery slider with thumbnails navigation.
     */
    $('[data-js="gallery-slider"]').each(function (index) {
      var status = $(this).find('[data-js="gallery-slider__pages"]');
      var arrows = $(this).find('[data-js="gallery-slider__full-nav"]');
      var slickElement = $(this).find('[data-js="gallery-slider__full"]');
      var slickElementThumb = $(this).find('[data-js="gallery-slider__thumb"]');

      slickElement.on(
        "init reInit afterChange",
        function (event, slick, currentSlide, nextSlide) {
          //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
          var i = (currentSlide ? currentSlide : 0) + 1;
          status.text(i + " of " + slick.slideCount);
        }
      );

      slickElement.slick({
        dots: false,
        arrows: true,
        fade: true,
        asNavFor: slickElementThumb,
        appendArrows: arrows,
      });

      slickElementThumb.slick({
        dots: false,
        arrows: true,
        slidesToShow: 4,
        // centerMode: true,
        focusOnSelect: true,
        asNavFor: slickElement,
      });
    });
  }

  magnific: {
    // IFRAME
    $('[data-js="video-popup"]').magnificPopup({
      type: "iframe",
      mainClass: "mfp-video-wrapper my-mfp-zoom-in",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,

      iframe: {
        markup:
          '<div class="mfp-iframe-scaler">' +
          '<div class="mfp-close"></div>' +
          '<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
          "</div>",
        patterns: {
          youtu: {
            index: "youtu.be",
            id: function (url) {
              // Capture everything after the hostname, excluding possible querystrings.
              var m = url.match(/^.+youtu.be\/([^?]+)/);

              if (null !== m) {
                return m[1];
              }

              return null;
            },
            // Use the captured video ID in an embed URL.
            // Add/remove querystrings as desired.
            src: "//www.youtube.com/embed/%id%?autoplay=1&rel=0",
          },
          youtube: {
            index: "youtube.com/",
            id: "v=",
            src: "https://www.youtube.com/embed/%id%?autoplay=1",
          },
        },
      },
    });

    // INLINE
    $('[data-js="inline-popup"]').magnificPopup({
      type: "inline",

      fixedContentPos: false,
      fixedBgPos: true,

      overflowY: "auto",

      closeBtnInside: true,
      preloader: false,

      midClick: true,
      removalDelay: 300,
      mainClass: "small-inline-popup my-mfp-zoom-in",
      gallery: {
        enabled: true,
      },
    });
    // SEARCH
    $('[data-js="search-popup"]').magnificPopup({
      type: "inline",

      fixedContentPos: false,
      fixedBgPos: true,

      overflowY: "auto",

      closeBtnInside: true,
      preloader: false,

      midClick: true,
      removalDelay: 300,
      mainClass: "search-inline-popup my-mfp-zoom-in",
      gallery: {
        enabled: true,
      },
    });
    $('[data-js="team-inline-popup"]').magnificPopup({
      type: "inline",

      fixedContentPos: false,
      fixedBgPos: true,

      overflowY: "auto",

      closeBtnInside: true,
      preloader: false,

      midClick: true,
      removalDelay: 300,
      mainClass: "team-inline-popup my-mfp-zoom-in",
      gallery: {
        enabled: true,
      },
    });

    // GALLERY SINGLE IMAGE
    $(".magnific-gallery-single-image").magnificPopup({
      type: "image",
      closeOnContentClick: true,
      mainClass: "mfp-img-single my-mfp-zoom-in",
      image: {
        verticalFit: true,
        titleSrc: function titleSrc(item) {
          return item.el.attr("title");
        },
      },
      gallery: {
        enabled: false,
      },
    });

    // GALLERY IMAGE
    $(".magnific-gallery-image").magnificPopup({
      type: "image",
      closeOnContentClick: true,
      mainClass: "mfp-img-mobile my-mfp-zoom-in",
      image: {
        verticalFit: true,
        titleSrc: function (item) {
          return item.el.attr("title");
        },
      },
      gallery: {
        enabled: true,
      },
    });
  }

  customCursor: {
    function updateCustomCursorPosition(e, customCursor, parentClass, offset) {
      let { pageX, pageY, target } = e;

      if (target.matches(parentClass) || target.closest(parentClass)) {
        // pageY = pageY - offset;
        console.info(pageY);
        gsap.to(customCursor, {
          x: pageX,
          y: pageY,
          duration: 0.3,
        });
      }

      updateCustomCursorStyle(e, customCursor, parentClass);
    }

    function updateCustomCursorStyle(e, customCursor, parentClass, offset) {
      // add custom styles to the cursor based on the target of the mouse during the mouseover event

      const { target } = e;
      const { classList } = customCursor;
      const body = document.querySelector("body");

      // check if data-js="navigation"

      if (
        (target.matches(parentClass) || target.closest(parentClass)) &&
        !classList.contains("force-hide") &&
        !target.closest('[data-js="navigation]')
      ) {
        classList.add("opacity-100");
        classList.remove("opacity-0");
        body.style.cursor = "none";
      } else {
        classList.add("opacity-0");
        classList.remove("opacity-100");

        body.style.cursor = "auto";
      }
    }

    function Cursor(cursor, parentClass) {
      // - cursor element hidden on all pages
      // - add hover event listener to all elements that should show mouse when hovered over
      // - if hovering over an element, display cursor element

      const yOffset = 0;
      // const bodyRect = document.body.getBoundingClientRect();
      // 	const elemRect = document.querySelector(parentClass).getBoundingClientRect()
      // 	// const yOffset = elemRect.top - bodyRect.top
      // 	const yOffset = window.pageYOffset + document.querySelector(parentClass).getBoundingClientRect().top;
      if (!cursor || window.matchMedia("(max-width: 1200px)").matches) return;

      document.addEventListener(
        "mousemove",
        function (e) {
          updateCustomCursorPosition(e, cursor, parentClass, yOffset);
        },
        false
      );

      document.addEventListener(
        "wheel",
        function (e) {
          updateCustomCursorPosition(e, cursor, parentClass, yOffset);
        },
        false
      );

      // document.addEventListener('dragover', function(e) {
      //     updateCustomCursorPosition(e, cursor, parentClass);
      // }, false);
    }
    // if (
    //   document.querySelectorAll('[data-js="custom-cursor-contain-home"]').length
    // ) {
    //   Cursor(
    //     document.querySelector('[data-js="custom-cursor-home"]'),
    //     '[data-js="custom-cursor-contain-home"]'
    //   );
    // }
    if (
      document.querySelectorAll('[data-js="custom-cursor-contain-find-out-now"]').length
    ) {
      Cursor(
        document.querySelector('[data-js="custom-cursor-find-out-now"]'),
        '[data-js="custom-cursor-contain-find-out-now"]'
      );
    }
    if (
      document.querySelectorAll('[data-js="custom-cursor-contain-news"]').length
    ) {
      Cursor(
        document.querySelector('[data-js="custom-cursor-news"]'),
        '[data-js="custom-cursor-contain-news"]'
      );
    }
    if (
      document.querySelectorAll('[data-js="custom-cursor-contain-info-card"]')
        .length
    ) {
      Cursor(
        document.querySelector('[data-js="custom-cursor-info-card"]'),
        '[data-js="custom-cursor-contain-info-card"]'
      );
    }
    // Cursor(document.querySelector('.custom-cursor--video'), '.js-custom-cursor-video');

    // const customCursors = document.querySelectorAll('[data-js="custom-cursor-contain"]');

    // customCursors.forEach(customCursor => {
    // 	Cursor('[data-js="custom-cursor"]', '[data-js="custom-cursor-contain"]');

    // 	})
  }

  accordion: {
    if (document.querySelectorAll('[data-js="accordion"]').length) {
      const accordions = document.querySelectorAll(
        '[data-js="accordion"] button'
      );

      accordions.forEach((accordion) => {
        const target = accordion.getAttribute("aria-controls");
        const content = document.querySelector("#" + target);

        gsap.set(content, { height: 0 });

        accordion.addEventListener("click", function (e) {
          const hrIcon = accordion.nextElementSibling.nextElementSibling;
          const target = accordion.getAttribute("aria-controls");
          const content = document.querySelector("#" + target);

          if (!accordion.classList.contains("accordion-open")) {
            gsap.to(hrIcon, {
              scaleY: 0,
              transformOrigin: "center",
              duration: 0.3,
              onComplete: ScrollTrigger.refresh
            });
            gsap.set(content, { height: "auto" });
            gsap.from(content, { height: 0, onComplete: ScrollTrigger.refresh });
            accordion.classList.add("accordion-open");
          } else {
            gsap.to(hrIcon, {
              scaleY: 1,
              transformOrigin: "center",
              duration: 0.3,
            });
            gsap.to(content, { height: 0, onComplete: ScrollTrigger.refresh });

            accordion.classList.remove("accordion-open");

          }
        });
      });

      // $('[data-js="accordion"] button').on('click', function () {
      // 	console.info('cl');
      // 	// $(this).closest('h3')
      // 	let hrIcon = $(this).nextElementSibling.nextElementSibling;
      // 	console.info(hrIcon)
      // 	// Close only the items in this accordion.
      // 	$(this).parent().siblings().find('.js-accordion-trigger').next().slideUp('fast'); // reset
      // 	$(this).parent().siblings().find('.js-accordion-trigger').removeClass('open');    // reset

      // 	// Close items in all accordions on the page.
      // 	//$('.js-accordion-trigger').not(this).next().slideUp('fast'); // reset
      // 	//$('.js-accordion-trigger').not(this).removeClass('open');    // reset

      // 	if ($(this).hasClass('open')) {
      // 		$(this).next().slideUp('fast');
      // 		$(this).removeClass('open');
      // 	} else {
      // 		$(this).next().slideDown('fast');
      // 		$(this).addClass('open');
      // 	}

      // });
    }
  }

  coreValuesAccordion: {
    if (document.querySelectorAll('.home').length) {
      const accordions = document.querySelectorAll(
        '[data-js="accordion"] button'
      );

      accordions.forEach((accordion) => {

        let accordionFiltered = [...accordions].filter(
          (accordionTest) => {
            return (
              accordionTest.id !=
              accordion.id
            );
          }
        );

        accordion.addEventListener("mouseenter", () => {
gsap.to(accordionFiltered, {opacity: 0.4});
        })
        accordion.addEventListener("mouseleave", () => {
          gsap.to(accordionFiltered, {opacity: 1});
                  })
      })
    }
  }

  tabs: {
    const tabs = document.querySelectorAll('[role="tab"]');
    const tabList = document.querySelector('[role="tablist"]');

    if (tabs) {
      // Add a click event handler to each tab
      tabs.forEach((tab) => {
        tab.addEventListener("click", function (e) {
          changeTabs(e.currentTarget);
        });
      });

      // Enable arrow navigation between tabs in the tab list
      let tabFocus = 0;

      if (tabList) {
        tabList.addEventListener("keydown", (e) => {
          // Move right
          if (e.keyCode === 39 || e.keyCode === 37) {
            tabs[tabFocus].setAttribute("tabindex", -1);
            if (e.keyCode === 39) {
              tabFocus++;
              // If we're at the end, go to the start
              if (tabFocus >= tabs.length) {
                tabFocus = 0;
              }
              // Move left
            } else if (e.keyCode === 37) {
              tabFocus--;
              // If we're at the start, move to the end
              if (tabFocus < 0) {
                tabFocus = tabs.length - 1;
              }
            }

            tabs[tabFocus].setAttribute("tabindex", 0);
            tabs[tabFocus].focus();
          }
        });
      }

      function changeTabs(target) {
        //control container - find tablist
        const controlList = target.closest('[role="tablist"]');

        const targetID = target.getAttribute("aria-controls");
        const lastIndex = targetID.lastIndexOf("-");
        const allContentSelector = targetID.slice(0, lastIndex);
        const contentList = document.querySelectorAll(
          '[id^="' + allContentSelector + '"]'
        );

        // Remove all current selected tabs
        controlList.querySelectorAll('[aria-selected="true"]').forEach((t) => {
          t.setAttribute("aria-selected", false);
          t.classList.remove("tab-active");
        });

        // Set this tab as selected
        target.setAttribute("aria-selected", true);
        target.classList.add("tab-active");

        // Hide all tab panels
        contentList.forEach((p) => p.setAttribute("hidden", true));

        // Show the selected panel
        const selectedPanel = document.querySelector(`#${targetID}`);
        selectedPanel.removeAttribute("hidden");

        let tl = gsap.timeline({});
        tl.to(contentList, { autoAlpha: 0, duration: 0.3 })
          .set(contentList, { height: 0 })
          .set(selectedPanel, { height: "auto" })
          .to(selectedPanel, { autoAlpha: 1, duration: 0.3 });
      }
    }
  }

  loadingAnimations: {
    $.fn.isOnScreen = function () {
      var win = $(window);
      var viewport = {
        top: win.scrollTop(),
      };
      viewport.bottom = viewport.top + win.height() - 80;

      var bounds = this.offset();
      bounds.bottom = bounds.top + this.outerHeight();

      return !(viewport.bottom < bounds.top || viewport.top > bounds.bottom);
    };

    // First load Animation
    $(".off-screen").each(function (index) {
      if ($(this).isOnScreen()) {
        $(this).removeClass("off-screen--hide");
      }
    });

    // Animation on scroll
    $(window).scroll(function () {
      $(".off-screen").each(function (index) {
        if ($(this).isOnScreen()) {
          $(this).removeClass("off-screen--hide");
        }
      });
    });
  }

  // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
});
